<template>
  <div class="complete">
    <div class="title">
      <img src="./img/success.png" alt="" /><span>您的报名已提交成功</span>
    </div>
    <div class="image">
      <img src="./img/complete.png" alt="" />
    </div>
    <div class="btn blue" @click="toActivityList">继续报名</div>
    <div class="btn white" @click="toMyActivityList">去查看</div>
  </div>
</template>

<script>
export default {
  name: "appointmentComplete",
  data() {
    return {
      activityId: "",
    };
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
  },
  methods: {
    // 去活动列表页面
    toActivityList() {
      this.$router.replace({
        name: "applyActivity",
      });
    },
    // 去我的活动列表页面
    toMyActivityList() {
      this.$router.replace({
        name: "myActivityList",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.complete {
  box-sizing: border-box;
  min-height: 100%;
  background: #fafafaff;
  padding: 206px 68px 138px 68px;
  margin-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  margin-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 40px;
      height: 40px;
      margin-right: 20px;
      vertical-align: middle;
      overflow: hidden;
    }
    span {
      font-size: 36px;
      font-weight: bold;
      color: #3680ffff;
      text-align: center;
    }
  }
  .image {
    width: 550px;
    height: 320px;
    margin: 126px auto 182px;
    img {
      width: 100%;
      height: 100%;
      overflow: hidden;
      vertical-align: middle;
    }
  }
  .btn {
    width: 550px;
    height: 66px;
    margin: 0px auto 30px;
    font-size: 30px;
    line-height: 66px;
    text-align: center;
    font-weight: bold;
    border-radius: 10px;
    &.blue {
      color: #ffffff;
      background: linear-gradient(180deg, #72a8ff 0%, #3680ff 100%);
    }
    &.white {
      color: #3680ff;
      border: 2px solid rgba(110, 165, 255, 1);
      // border-image: linear-gradient(
      //     180deg,
      //     rgba(110, 165, 255, 1),
      //     rgba(49, 125, 255, 1)
      //   )
      //   2 2;
    }
  }
}
</style>
